import React, { useEffect, useState } from "react";
import $ from "jquery";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_BANNER_DIALOG } from "../../store/banner/types";

//action
import { addCoinSeller, editCoinSeller } from "../../store/coinSeller/action";
import { baseURL } from "../../util/Config";
import { permissionError } from "../../util/Alert";
import { CLOSE_DIALOGUE } from "../../store/coinSeller/type";

const CoinSellerAdd = (props) => {
  const dispatch = useDispatch();

  const { dialogOpen: open, dialogData } = useSelector(
    (state) => state.coinSeller
  );
  const hasPermission = useSelector((state) => state.admin.admin.flag);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [code, setCode] = useState("");
  const [errors, setError] = useState({
    name: "",
    email: "",
    password: "",
    mobileNo: "",
    countryCode: "",
    image: "",
  });

  useEffect(() =>   {
    setName("");
    setPassword("")
    setEmail("");
    setCode("");
    setImage([]);
    setImagePath("");
    setMobileNo("")
    setError({
      name: "",
      email: "",
      password: "",
      mobileNo: "",
      countryCode: "",
      image: "",
    });
  }, [open]);

  useEffect(() => {
    if (dialogData) {
      setName(dialogData?.name);
      setEmail(dialogData?.email);
      setCode(dialogData?.countryCode);
      setPassword(dialogData?.password);
      setImagePath(dialogData?.image);
      setMobileNo(dialogData?.mobileNo);
  
    }
  }, [dialogData]);

  const showPassword = () => {
    if ("password" === $("#password").attr("type")) {
      $("#password").prop("type", "text");
      $("#showPassword")
        .removeClass("fa fa-eye")
        .addClass("fa-solid fa-eye-slash");
    } else {
      $("#password").prop("type", "password");
      $("#showPassword")
        .removeClass("fa-solid fa-eye-slash")
        .addClass("fa fa-eye");
    }
  };

  useEffect(() => {
    window.onbeforeunload = closePopup();
  }, []);

  const HandleInputImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    if (!e.target.files[0]) {
      return setError({
        ...errors,
        image: "Image is Required!",
      });
    } else {
      return setError({
        ...errors,
        image: "",
      });
    }
  };

  const closePopup = () => {
    dispatch({ type: CLOSE_DIALOGUE });
  };

  const isLink = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^(ftp|http|https):\/\/[^ "]+$/.test(val);
    return validNumber;
  };

  const handleSubmit = () => {
    if (!name || !email || !password || !imagePath || !mobileNo || !code) {
      const error = {};
      if (!name) error.name = "Name Is Required !";
      if (!password) error.password = "password Is Required !";
      if (!email) error.email = "email Is Required !";
      if (!mobileNo) error.mobileNo = "Mobile number is required !";
      if (!code) error.code = "countryCode is required !";
      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("password", password);
      formData.append("email", email);
      formData.append("image", image);
      formData.append("mobileNo", mobileNo);
      formData.append("countryCode", code);

      if (dialogData) {
        props.editCoinSeller(dialogData._id, formData);
      } else {
        props.addCoinSeller(formData);
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Coin Seller </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="mb-2 text-gray">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        placeholder="Coin Seller Name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              name: "Name is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              name: "",
                            });
                          }
                        }}
                      />
                      {errors.name && (
                        <div className="ml-2 mt-1">
                          {errors.name && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.name}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="mb-2 text-gray">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        required=""
                        placeholder="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              email: "email is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              email: "",
                            });
                          }
                        }}
                      />
                      {errors.email && (
                        <div className="ml-2 mt-1">
                          {errors.email && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.email}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="mb-2 text-gray">Code </label>
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        required=""
                        placeholder="+91"
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              code: "code is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              code: "",
                            });
                          }
                        }}
                      />
                      {errors.code && (
                        <div className="ml-2 mt-1">
                          {errors.code && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.code}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="form-group">
                      <label className="mb-2 text-gray">Mobile No</label>
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        required=""
                        placeholder="98547 xxxx"
                        value={mobileNo}
                        onChange={(e) => {
                          setMobileNo(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              mobileNo: "mobileNo is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              mobileNo: "",
                            });
                          }
                        }}
                      />
                      {errors.mobileNo && (
                        <div className="ml-2 mt-1">
                          {errors.mobileNo && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.mobileNo}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                  <div className="form-group mb-0">
                    <p className="form-label fw-bold mt-3"> Password</p>
                  </div>
                  <div class="input-group ">
                    <div class="input-group" id="Password-toggle">
                      <a href={() => false} class="input-group-text">
                        <i
                          id="showPassword"
                          class="fa fa-eye"
                          autoComplete="off"
                          aria-hidden="true"
                          onClick={showPassword}
                        ></i>
                      </a>
                      <input
                        type="password"
                        id="password"
                        placeholder="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (!e.target.value)
                            return setError({
                              ...errors,
                              password: "password Is Required !",
                            });
                          else {
                            return setError({
                              ...errors,
                              password: "",
                            });
                          }
                        }}
                      />
                    </div>
                    {errors.password && (
                      <div className="ml-2 mt-1">
                        {errors.password && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.password}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
               

                <div className="form-group mt-4">
                  <label className="mb-2 text-gray">Seller Image</label>
                  <input
                    type="file"
                    className="form-control form-control-sm"
                    accept="image/*"
                    required=""
                    onChange={HandleInputImage}
                  />
                  {errors.image && (
                    <div className="ml-2 mt-1">
                      {errors.image && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.image}</span>
                        </div>
                      )}
                    </div>
                  )}
                  {imagePath && (
                    <>
                      <img
                        height="70px"
                        width="70px"
                        alt="app"
                        src={imagePath}
                        style={{
                          boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                          // border: "2px solid #fff",
                          borderRadius: 10,
                          marginTop: 10,
                          float: "left",
                          objectFit: "cover",
                        }}
                      />
                    </>
                  )}
                </div>
                <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { addCoinSeller, editCoinSeller })(CoinSellerAdd);
