export const GET_USER = "GET_USER";
export const BLOCK_UNBLOCK_SWITCH = "BLOCK_UNBLOCK_SWITCH";

export const GET_HISTORY = "GET_HISTORY";

export const EDIT_COIN = "EDIT_COIN";

export const  GET_LIVE_USER ="GET_LIVE_USER";

export const LIVE_CUT ="LIVE_CUT";

export const ENABLE_TO_LIVE = "ENABLE_TO_LIVE";

export const ENABLE_VIDEOCALL = "ENABLE_VIDEOCALL";


export const GAME_BLOCK = "GAME_BLOCK"; 

export const CALL_LEVEL = "CALL_LEVEL";
