import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//pagination
import Pagination from "../../pages/Pagination";

//Date Range Picker
import { DateRangePicker } from "react-date-range";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import dayjs from "dayjs";
import { connect, useSelector } from "react-redux";
import { getGameHistory , resetGameCoin } from "../../store/GameHistory/action";

const GameHistory = (props) => {
  const { gameHistory, total, adminCoin } = useSelector(
    (state) => state.gameHistory
  );

  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    props.getGameHistory(activePage, rowsPerPage, sDate, eDate);
  }, [activePage, rowsPerPage, sDate, eDate]);

  useEffect(() => {
    setData(gameHistory);
  }, [gameHistory]);

  useEffect(() => {
    $("#card").click(() => {
      $("#datePicker");
    });
  }, []);

  useEffect(() => {
    if (date.length === 0) {
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    $("#datePicker");
    setData(gameHistory);
  }, [date, gameHistory]);

  const getAllUser = () => {
    setActivePage(1);
    setsDate("ALL");
    seteDate("ALL");
    $("#datePicker");
    props.getGameHistory(activePage, rowsPerPage, sDate, eDate);
  };

  const collapsedDatePicker = () => {
    $("#datePicker").toggleClass("collapse");
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };


  const handleResetCoin = () => {
    props.resetGameCoin()
  }
  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3">Game History</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Game History
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                  <button
                      className="btn btn-light text-info"
                      style={{ marginRight: 5  }}
                      onClick={handleResetCoin}
                    >
                      Reset Coin
                    </button>
                    <button
                      className="btn btn-info"
                      style={{ marginRight: 5 }}
                      onClick={getAllUser}
                    >
                      All
                    </button>
                    <button
                      className="collapsed btn btn-info ml-5"
                      value="check"
                      data-toggle="collapse"
                      data-target="#datePicker"
                      onClick={collapsedDatePicker}
                    >
                      Analytics
                      <ExpandMoreIcon />
                    </button>
                    <p style={{ paddingLeft: 10 }} className="my-2 ">
                      {sDate !== "ALL" && sDate + " to " + eDate}
                    </p>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  <div className="d-flex justify-content-end mt-3">
                    <span className="text-danger">
                      Admin Total Coin :
                      <span className="text-info">&nbsp;&nbsp;{adminCoin}</span>
                    </span>
                  </div>
                </div>
                <div
                  id="datePicker"
                  className="collapse mt-5 pt-5"
                  aria-expanded="false"
                >
                  <div className="container table-responsive">
                    <div key={JSON.stringify(date)}>
                      <DateRangePicker
                        onChange={(item) => {
                          setDate([item.selection]);
                          const dayStart = dayjs(
                            item.selection.startDate
                          ).format("YYYY-MM-DD");
                          const dayEnd = dayjs(item.selection.endDate).format(
                            "YYYY-MM-DD"
                          );
                          setActivePage(1);
                          setsDate(dayStart);
                          seteDate(dayEnd);
                          props.getGameHistory(
                            activePage,
                            rowsPerPage,
                            sDate,
                            eDate
                          );
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        direction="horizontal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow">
              <table class="table" style={{ borderCollapse: "collapse" }}>
                <thead class="text-center">
                  <tr>
                    <th>No</th>
                    {/* <th>winner Card</th> */}
                    <th>Admin Coin</th>
                    <th>Win/Lose</th>
                    <th>winner Coin Minus</th>
                    <th>Total Add Coin</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Info</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                      var gameDate = data?.date ? data?.date.split(",") : [];

                      return (
                        <>
                          <tr
                            data-toggle="collapse"
                            data-target={`#demo${index}`}
                            class="accordion-toggle pointer-cursor"
                          >
                            <td>{index + 1}</td>

                            {/* <td>{data?.winnerIndex}</td> */}
                            <td class="text-success">
                              {data?.updatedAdminCoin}
                            </td>
                            <td class={data?.totalAdd +  data?.winnerCoinMinus > 0 ?"text-success" :"text-danger" }>
                              {data?.totalAdd + data?.winnerCoinMinus > 0
                                ? data?.totalAdd + data?.winnerCoinMinus
                                : Math.abs(
                                    data?.totalAdd + data?.winnerCoinMinus
                                  )}
                            </td>
                            <td class="text-danger">
                              {parseInt(data?.winnerCoinMinus)}
                            </td>
                            <td class="text-warning">{data?.totalAdd}</td>
                          
                            <td className="text-info">{gameDate[0]}</td>
                            <td className="text-primary">{gameDate[1]}</td>

                            <td className="pointer-cursor">
                              <i className="fas fa-info-circle fa-lg"></i>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="8" class="hiddenRow">
                              <div
                                id={`demo${index}`}
                                class="accordian-body collapse"
                              >
                                <h6 className="text-danger">Frame History</h6>
                                <table className="w-100">
                                  <thead class="text-center ">
                                    <tr class="">
                                      <th>No</th>
                                      <th>select Frame</th>
                                      <th>bit</th>
                                      <th>card</th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    class="text-center"
                                    style={{
                                      maxHeight: 100,
                                      overflowY: "auto",
                                    }}
                                  >
                                    {data?.cardCoin?.length > 0 ? (
                                      data?.cardCoin.map((report, no) => {
                                        return (
                                          <tr key={no} class="border-top ">
                                            <td>{no + 1}</td>
                                            <td
                                              className={`${
                                                report?.winner
                                                  ? "text-success"
                                                  : "text-danger"
                                              } fw-bold`}
                                            >
                                              {report?.selectFrame}
                                            </td>

                                            <td class="text-warning">
                                              {report?.bit}
                                            </td>
                                            <td class="text-info">
                                              {report?.card}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan="7" align="center">
                                          Nothing to show!!
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="7" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={total}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getGameHistory  ,resetGameCoin})(GameHistory);
