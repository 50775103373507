import axios from "axios";
import { Toast } from "../../../src/util/Toast";
import * as CoinSellerType from "./type";

// GET coinSeller
export const getCoinSeller = () => (dispatch) => {
  axios
    .get(`coinSeller?type=admin`)
    .then((res) => {
      dispatch({
        type: CoinSellerType.GET_COINSELLER,
        payload: res.data.coinSeller,
      });
    })
    .catch((error) => console.log(error));
};

// CREATE coinSeller
export const addCoinSeller = (formData) => (dispatch) => {
  axios
    .post("coinSeller", formData)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: CoinSellerType.ADD_COINSELLER,
          payload: res.data.coinSeller,
        });
        Toast("success", "coinSeller Add Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

// UPDATE coinSeller
export const editCoinSeller = (id, fromData) => (dispatch) => {
  axios
    .patch(`coinSeller/${id}`, fromData)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: CoinSellerType.EDIT_COINSELLER,
          payload: { coinSeller: res.data.coinSeller, id },
        });
        Toast("success", "coinSeller Update Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

// DELETE AGANCY
export const deleteCoinSeller = (id) => (dispatch) => {
  axios
    .patch(`coinSeller/disable/${id}`)
    .then((res) => {
      dispatch({
        type: CoinSellerType.DELETE_COINSELLER,
        payload: { data: res.data.data, id },
      });
    })
    .catch((error) => console.log(error));
};

// Show coinSeller
export const showCoinSeller = (id) => (dispatch) => {
  axios
    .patch(`coinSeller/show/${id}`)
    .then((res) => {
      dispatch({
        type: CoinSellerType.SHOW_COINSELLER,
        payload: { data: res.data.data, id },
      });
    })
    .catch((error) => console.log(error));
};



// UPDATE coinSeller
export const AddMoneyByAdmin = (data, id) => (dispatch) => {
  axios
    .post(`coinSeller/adminToCoinSeller/${id}`, data)
    .then((res) => {
      console.log(res.data.coinSeller);
      if (res.data.status === true) {
        dispatch({
          type: CoinSellerType.ADD_MONEY_BY_ADMIN,
          payload: { coinSeller: res.data.coinSeller, id },
        });
        Toast("success", "Add coin Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

// GET coinSeller history
export const getCoinSellerHistory = (id) => (dispatch) => {
  axios
    .get(`coinSellerHistory/coinSeller/${id}`)
    .then((res) => {
      console.log(res.data)
      dispatch({
        type: CoinSellerType.GET_COINSELLER_HISTORY,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};


