import axios from "axios";
import { GET_GAME_HISTORY, RESET_COIN } from "./type";
import {
  CLOSE_SPINNER_PROGRESS,
  OPEN_SPINNER_PROGRESS,
} from "../spinner/types";
import { Toast } from "../../util/Toast";

export const getGameHistory =
  (start, limit, startDate, endDate) => (dispatch) => {
    dispatch({ type: OPEN_SPINNER_PROGRESS });
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      `https://game.vivins.online/gameHistory/get?start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("res", res);

        dispatch({ type: GET_GAME_HISTORY, payload: res });
      })
      .catch((error) => console.error(error))
      .finally(() => {
        dispatch({ type: CLOSE_SPINNER_PROGRESS });
      });
  };

export const resetGameCoin = () => (dispatch) => {
  axios
    .get("/gameAdminCoin/reset")
    .then((res) => {
     
      if (res.data.status) {
        dispatch({ type: RESET_COIN, payload: res.data.gameAdminCoin.coin });
        Toast("success", "Admin Coin Reset Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error));
};
