import React, { useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";
import Pagination from "../../pages/Pagination";
import Male from "../../assets/images/male.png";
import dayjs from "dayjs";
import { Toast } from "../../../src/util/Toast";

import { baseURL, key } from "../../util/Config";

const Payroll = () => {
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("ALL");
  const [openDialog, setOpenDialog] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchDialog, setSearchDialog] = useState("");

  // New state variables for mobile number dialog
  const [openMobileDialog, setOpenMobileDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [mobile, setmobile] = useState("");

  const [openCoinDialog, setOpenCoinDialog] = useState(false);
const [coinAmount, setCoinAmount] = useState(0);

const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
const [selectedSellerId, setSelectedSellerId] = useState(null);


  useEffect(() => {
    // Fetch the coin sellers from your API (replace with the actual API call)
    // fetchCoinSellers();
    fetchUsersForCoinSeller()
  }, []);



  const fetchCoinSellers = async () => {
    try {
      const response = await fetch(`${baseURL}users-coin-sellers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          key: key, // API key from config
        },
      });
      console.log("response: ", response);
      const result = await response.json();
      console.log("result: ", result);
      setData(result.users); // Assuming result contains a `coinSellers` array
    } catch (error) {
      console.error("Error fetching coin sellers:", error);
    }
  };
  

  

  const handleOpen = () => {
    setOpenDialog(true); // Open the dialog
    // fetchUsersForCoinSeller(); // Fetch users when dialog opens
    fetchCoinSellers();
  };

  const handleClose = () => {
    setOpenDialog(false); // Close the dialog
    // fetchCoinSellers();
    fetchUsersForCoinSeller();
  };

  const fetchUsersForCoinSeller = async () => {
    try {
      const response = await fetch(`${baseURL}users-payroll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          key: key, // Add key in headers
        },
      });
      const result = await response.json();
      console.log("result: ", result);
      setUsers(result.users); // Assuming the API returns data in this format
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase();
    if (value) {
      const filteredData = users.filter((item) => {
        return (
          item?.name?.toUpperCase()?.includes(value) || 
          item?.username?.toLowerCase().includes(value)
        );
      });
      // setData(filteredData);
      setUsers(filteredData);
    } else {
      fetchUsersForCoinSeller(); // Reset data to original when search is cleared
    }
  };

  const handleDialogSearch = (e) => {
    const value = e.target.value.toLowerCase();
    console.log("Value: ", value);
    setSearchDialog(value);

    if (value) {
      const filteredUsers = data.filter((user) =>
        user.name.toLowerCase().includes(value)|| 
      user.username.toLowerCase().includes(value)
      );
      // setUsers(filteredUsers);
      setData(filteredUsers);
    } else {
      // fetchUsersForCoinSeller(); // Reset to the full user list if the search is empty
      fetchCoinSellers();
    }
  };

  const makePayroll = async (user) => {
      await callMakePayrollApi(user._id);
  };

  const handleMobileDialogClose = () => {
    setOpenMobileDialog(false);
    setmobile("");
    setSelectedUserId("");
  };

  const handleMobileSubmit = async () => {
    if (!mobile) {
      Toast("error", "Please enter mobile no.");
      return;
    }
    if (mobile.length !== 10 || !/^\d{10}$/.test(mobile)) {
      Toast("error", "Mobile number should be exactly 10 digits");
      return;
    }
    // Proceed with API call with mobile
    await callMakePayrollApi(selectedUserId);
    // Close the mobile number dialog
    handleMobileDialogClose();
  };

  const callMakePayrollApi = async (userId) => {
    try {
      const payload = {
        userId: userId, // Pass the user ID in the request body
      };

      const response = await fetch(`${baseURL}coinSeller/make-payroll`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      console.log("Make Payroll Response:", result);
      // Handle success or error response as needed
      if (result.status) {
        // alert("User has been made a coin seller successfully.");
        Toast("success", result.message);
        // Refresh the coin sellers list

        // fetchUsersForCoinSeller();
        fetchCoinSellers();
      } else {
        Toast("error", result.message);
      }
    } catch (error) {
      console.error("Error making Payroll:", error);
    }
  };




  const handleRemoveCoinSellerClick = (coinSellerId) => {
    console.log("coins seller ID: ", coinSellerId);
    setSelectedSellerId(coinSellerId); // Store the selected seller's ID
    setOpenDeleteDialog(true); // Open the dialog
  };


  const handleDeleteSeller = async (userId) => {
    try {
      const payload = {
        userId: userId,
      };
      const response = await fetch(`${baseURL}coinSeller/delete-payroll`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      console.log("delete Payroll Response:", result);
      if (result.status) {
        Toast("success", result.message);
        setOpenDeleteDialog(false);

        // fetchCoinSellers();
        fetchUsersForCoinSeller();
      } else {
        Toast("error", result.message);
        setOpenDeleteDialog(false);

      }
    } catch (error) {
      console.error("Error deleting coin seller:", error);
    }
  };
  

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3" style={{ color: "#e4eeff" }}>
              Payroll Users
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  Payroll
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <button
                    type="button"
                    className="btn waves-effect waves-light btn-danger btn-sm float-left"
                    onClick={handleOpen}
                    id="CoinSellerAdd"
                  >
                    <i className="fa fa-plus"></i>
                    <span className="icon_margin">New</span>
                  </button>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <div className="input-group-prepend border-0">
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={handleSearch}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card-body card-overflow pt-0">
              <table className="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>User Id</th>
                    <th>Email</th>
                    {/* <th>Mobile No</th>
                    <th>Receive Coin</th>
                    <th>Spend Coin</th>
                    <th>Coin</th> */}
                    <th>Remove Payroll</th>
                    <th>User Joined On</th>
                  </tr>
                </thead>
                <tbody>
                  {users && users.length > 0 ? (
                    users.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <img
                            height="50px"
                            width="50px"
                            alt="app"
                            src={item?.image || Male}
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              float: "left",
                              objectFit: "cover",
                            }}
                          />
                        </td>
                        <td>{item?.name}</td>
                        <td>{item?.username}</td>
                        <td>{item?.email}</td>
                        {/* <td>{item?.coinSellerDetails[0]?.mobileNo}</td>
                        <td>{item?.coinSellerDetails[0]?.receiveCoin}</td>
                        <td>{item?.coinSellerDetails[0]?.spendCoin}</td>
                        <td>{item?.coinSellerDetails[0]?.coin}</td> */}
                        <td>
                          <Tooltip title="Remove Coin Seller">
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => handleRemoveCoinSellerClick(item?._id)}
                            >
                              <i className="fa fa-trash fa-lg"></i>
                            </button>
                          </Tooltip>
                        </td>

                        <td>
                          {dayjs(item?.createdAt).format("DD MMM YYYY")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p>No Data Found</p>
                  )}
                </tbody>
              </table>
              <div className="mt-2">
                <Pagination
                  activePage={activePage}
                  rowsPerPage={rowsPerPage}
                  userTotal={data && data.length}
                  handlePageChange={handlePageChange}
                  handleRowsPerPage={handleRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Dialog to show users fetched for Coin Seller */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        // fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>Choose Users for Payroll</DialogTitle>
        <DialogContent>
          <TextField
            label="Click to search by username or userid"
            value={searchDialog}
            onChange={handleDialogSearch}
            fullWidth
            margin="dense"
            InputLabelProps={{
              style: { color: "#8fe3eb" }, // Label text color
            }}
            InputProps={{
              style: { color: "#eb9f8f" }, // Input text color
            }}
          />
          <table className="table table-striped mt-3">
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>User Id</th>
                <th>Mobile No</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user.name}</td>
                    <td>{user.username}</td>
                    <td>{user.mobile || "N/A"}</td>
                    <td>
                      <Button
                        variant="contained"
                        color={user.isPayroll ? "secondary" : "primary"} // Change color if already a seller
                        onClick={() => !user.isPayroll && makePayroll(user)} // Only allow click if not already a seller
                        disabled={user.isPayroll} // Disable button if user is already a seller
                        style={{
                          backgroundColor: user.isPayroll ? "#e0e0e0" : "", // Light gray background if disabled
                          color: user.isPayroll ? "#ff5722" : "#fff", // Orange text color for disabled, white for active
                          cursor: user.isPayroll ? "not-allowed" : "pointer", // Pointer cursor for enabled, not-allowed for disabled
                        }}
                      >
                        {user.isPayroll ? "Already a Payroll" : "Make a Payroll"} {/* Conditionally render text */}
                      </Button>
                    </td>

                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No Users Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog to input mobile number */}
      <Dialog
        open={openMobileDialog}
        onClose={handleMobileDialogClose}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>Enter Mobile Number</DialogTitle>
        <DialogContent>
          <TextField
            label="Mobile Number"
            value={mobile}
            onChange={(e) => setmobile(e.target.value)}
            fullWidth
            margin="dense"
            required
            InputLabelProps={{
              style: { color: "violet" }, // Label text color
            }}
            InputProps={{
              style: { color: "violet" }, // Input text color
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMobileDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleMobileSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

{/* Dialog to remove from coin seller */}
<Dialog
  open={openDeleteDialog}
  onClose={() => setOpenDeleteDialog(false)}
  maxWidth="sm"
>
  <DialogTitle>Are you sure you want to remove Payroll</DialogTitle>
  <DialogActions>
    <Button
      onClick={() => setOpenDeleteDialog(false)}
      color="secondary"
    >
      Cancel
    </Button>
    <Button
      onClick={() => handleDeleteSeller(selectedSellerId)}
      color="primary"
    >
      Remove
    </Button>
  </DialogActions>
</Dialog>


    </>
  );
};

export default Payroll;









