import React, { useEffect, useState } from "react";

//dayjs
import dayjs from "dayjs";
import { baseURL, key } from "../../util/Config";
import { Toast } from "../../util/Toast";


//jquery
import $ from "jquery";

//redux
import { connect, useSelector } from "react-redux";

//action
import {
  getUser,
  handleBlockUnblockSwitch,
  handleEnableToLive,
  handleVideoCall,
  handleBlockGame,
} from "../../store/user/action";

//routing
import { Link, useHistory } from "react-router-dom";

//MUI
import { Tooltip, Select, MenuItem, FormControl, InputLabel ,  ListItemIcon, ListItemText} from "@material-ui/core";
// import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

// import arraySort from "array-sort";

//image
import Male from "../../assets/images/male.png";

import { permissionError } from "../../util/Alert";

//pagination
import Pagination from "../../pages/Pagination";

//Date Range Picker
import { DateRangePicker } from "react-date-range";

//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Star, AccessAlarm, ThreeDRotation } from "@material-ui/icons"; // Example icons

const UserTable = (props) => {
  const history = useHistory();

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  // const [coinSort, setCoinSort] = useState(true);
  // const [followerSort, setFollowerSort] = useState(true);
  // const [followingSort, setFollowingSort] = useState(true);
  const [data, setData] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("ALL");

  const [date, setDate] = useState([]);
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");

  // New state to store callLevel names
  // const [callLevelNames, setCallLevelNames] = useState({});

  useEffect(() => {
    const cardElement = document.getElementById("card");
    const datePickerElement = document.getElementById("datePicker");

    const handleCardClick = () => {
      datePickerElement.click();
      // Add a class to datePickerElement
      datePickerElement.classList.remove("show");
    };

    cardElement.addEventListener("click", handleCardClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      cardElement.removeEventListener("click", handleCardClick);
    };
  }, []);

  useEffect(() => {
    props.getUser(activePage, rowsPerPage, search, sDate, eDate); // eslint-disable-next-line
  }, [activePage, rowsPerPage, search, sDate, eDate]);

  const { user, activeUser, male, female, totalUser } = useSelector(
    (state) => state.user
  );
  useEffect(() => {
    setData(user);
  }, [user]);

  useEffect(() => {
    if (date.length === 0) {
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    $("#datePicker");
    setData(user);
  }, [date, user]);

    // // Fetch callLevel name
    // const fetchCallLevelName = async (callLevelId) => {
    //   try {
    //     const requestOptions = {
    //       method: "GET",
    //       headers: { key: key },
    //     };
    //     const response = await fetch(`https://service.bogosindia.com/get-user-call-level?callLevel=${callLevelId}`, requestOptions);
    //     const result = await response.json();
    //     console.log("result: ", result);
    //     return result?.callLevelData?.name || "N/A";
    //   } catch (error) {
    //     console.error("Error fetching call level name:", error);
    //     return "N/A";
    //   }
    // };
  
    // // Fetch and set all callLevel names when data is updated
    // useEffect(() => {
    //   const fetchAllCallLevelNames = async () => {
    //     const names = {};
    //     for (let userData of user) {
    //       if (userData.callLevel && !callLevelNames[userData.callLevel]) {
    //         const name = await fetchCallLevelName(userData.callLevel);
    //         names[userData.callLevel] = name;
    //       }
    //     }
    //     setCallLevelNames((prev) => ({ ...prev, ...names }));
    //   };
  
    //   fetchAllCallLevelNames();
    // }, [user]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const handleBlockUnblockSwitch_ = (userId) => {
    if (!hasPermission) return permissionError();
    props.handleBlockUnblockSwitch(userId);
  };

  const handleUserInfo = (user) => {
    history.push({ pathname: "/admin/user/detail", state: user });
  };
  const handleUserHistory = (user) => {
    history.push({
      pathname: "/admin/user/history",
      state: { id: user._id, name: user?.username },
    });
  };

  const handleEnableToLive_ = (id) => {
    props.handleEnableToLive(id); 
  };
  const handleVideoCall_ = (id) => {
    props.handleVideoCall(id);
  };
  const handleBlockGame_ = (id) => {
    props.handleBlockGame(id);
  };
  // const handleCoinSort = () => {
  //   setCoinSort(!coinSort);
  //   arraySort(data, "rCoin", { reverse: coinSort });
  // };
  // const handleFollowerSort = () => {
  //   setFollowerSort(!followerSort);
  //   arraySort(data, "followers", { reverse: followerSort });
  // };
  // const handleFollowingSort = () => {
  //   setFollowingSort(!followingSort);
  //   arraySort(data, "following", { reverse: followingSort });
  // };

  const getAllUser = () => {
    setActivePage(1);
    setsDate("ALL");
    seteDate("ALL");
    $("#datePicker");
    props.getUser(activePage, rowsPerPage, sDate, eDate);
  };

  const collapsedDatePicker = () => {
    $("#datePicker").toggleClass("collapse");
  };
    const [callLevelNames, setCallLevelNames] = useState({});
    const [availableCallLevels, setAvailableCallLevels] = useState([]);

    const fetchAllCallLevels = async () => {
      try {
        const requestOptions = {
          method: "GET",
          headers: { key: key },
        };
        const response = await fetch(
          `https://service.bogosindia.com/get-call-levels`,
          requestOptions
        );
        const result = await response.json();
        console.log("calllevels result: ", result);
        setAvailableCallLevels(result?.callLevels || []);
      } catch (error) {
        console.error("Error fetching call levels:", error);
      }
    };
  
    useEffect(() => {
      fetchAllCallLevels();
    }, []);
  
    const fetchCallLevelName = async (callLevelId) => {
      try {
        const requestOptions = {
          method: "GET",
          headers: { key: key },
        };
        const response = await fetch(
          `https://service.bogosindia.com/get-user-call-level?callLevel=${callLevelId}`,
          requestOptions
        );
        const result = await response.json();
        return result?.callLevelData?.name || "N/A";
      } catch (error) {
        console.error("Error fetching call level name:", error);
        return "N/A";
      }
    };
  
    useEffect(() => {
      const fetchAllCallLevelNames = async () => {
        const names = {};
        for (let userData of user) {
          if (userData.callLevel && !callLevelNames[userData.callLevel]) {
            const name = await fetchCallLevelName(userData.callLevel);
            names[userData.callLevel] = name;
          }
        }
        setCallLevelNames((prev) => ({ ...prev, ...names }));
      };
  
      fetchAllCallLevelNames();
    }, [user]);

    const handleCallLevelChange = async (userId, newCallLevel) => {
      try {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            key: key,
          },
          body: JSON.stringify({ userId, callLevel: newCallLevel }),
        };
        console.log("userId: ", userId);
        console.log("newCallLevel: ", newCallLevel);
        const response = await fetch(
          `https://service.bogosindia.com/update-user-call-level`,
          requestOptions
        );
        const result = await response.json();
        if (result.success) {
          setData((data) =>
            data.map((user) =>
              user._id === result.user._id
                ? { ...user, callLevel: result.user.callLevel }
                : user
            )
          );
          setCallLevelNames((prev) => ({
            ...prev,
            [userId]: newCallLevel,
          }));
          Toast("success", "Update Successful!!");
        } else {
          Toast("error", response.data.message);
        // }
        }
      } catch (error) {
        console.error("Error updating call level:", error);
      }
    };
    


  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3" style={{ color: "#e4eeff" }}>
              User
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  User
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-2 col-sm-12">
          <div class="row">
            <div class="col-lg-4">
              <div class="card stats-card">
                <div class="card-body">
                  <div class="stats-info">
                    <h5 class="card-title">
                      {male ? male : 0}
                      {/* <span class="stats-change stats-change-danger">-8%</span> */}
                    </h5>
                    <p class="stats-text">Male</p>
                  </div>
                  <div class="stats-icon change-danger">
                    <i class="material-icons">male</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card stats-card">
                <div class="card-body">
                  <div class="stats-info">
                    <h5 class="card-title">
                      {female ? female : 0}
                      {/* <span class="stats-change stats-change-danger">-8%</span> */}
                    </h5>
                    <p class="stats-text">Female</p>
                  </div>
                  <div class="stats-icon change-success">
                    <i class="material-icons">female</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card stats-card">
                <div class="card-body">
                  <div class="stats-info">
                    <h5 class="card-title">{activeUser}</h5>
                    <p class="stats-text">Total Active User</p>
                  </div>
                  <div class="stats-icon change-pink">
                    <i class="material-icons">people</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card"> 
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                    <button
                      className="btn btn-info"
                      style={{ marginRight: 5 }}
                      onClick={getAllUser}
                    >
                      All
                    </button>
                    <button
                      className="collapsed btn btn-info ml-5"
                      value="check"
                      data-toggle="collapse" 
                      data-target="#datePicker"
                      onClick={collapsedDatePicker}
                    >
                      Analytics
                      <ExpandMoreIcon />
                    </button>
                    <p style={{ paddingLeft: 10 }} className="my-2 ">
                      {sDate !== "ALL" && sDate + " to " + eDate}
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <div className="input-group-prepend border-0">
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setActivePage(1);
                        }}
                      />
                    </div>
                  </form>
                </div>
                <div
                  id="datePicker"
                  className="collapse mt-5 pt-5"
                  aria-expanded="false"
                >
                  <div className="container table-responsive">
                    <div key={JSON.stringify(date)}>
                      <DateRangePicker
                        onChange={(item) => {
                          setDate([item.selection]);
                          const dayStart = dayjs(
                            item.selection.startDate
                          ).format("YYYY-MM-DD");
                          const dayEnd = dayjs(item.selection.endDate).format(
                            "YYYY-MM-DD"
                          );
                          setActivePage(1);
                          setsDate(dayStart);
                          seteDate(dayEnd);
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        direction="horizontal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow pt-0">
              <table class="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Gender</th>
                    {/* <th onClick={handleCoinSort} style={{ cursor: "pointer" }}>
                      VCoin {coinSort ? " ▼" : " ▲"}
                    </th> */}
                    <th>VCoin</th>
                    <th>Country</th>
                    <th>Level</th>
                    <th>Follower</th>
                    {/* <th
                      onClick={handleFollowerSort}
                      style={{ cursor: "pointer" }}
                    >
                      Follower {followerSort ? " ▼" : " ▲"}
                    </th> */}
                    <th>Following</th>
                    {/* <th
                      onClick={handleFollowingSort}
                      style={{ cursor: "pointer" }}
                    >
                      Following {followingSort ? " ▼" : " ▲"}
                    </th> */}
                    <th>BlockLive</th>
                    <th>BlockVideo</th>
                    <th>BlockGame</th>
                    <th>isBlock</th>
                    <th>Call Level</th>
                    <th>Info</th>
                    <th>History</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data.image ? data.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                float: "left",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                          <td>{data.username}</td>
                          <td>{data.name}</td>
                          <td>{data.gender}</td>
                          <td className="text-danger">{data.rCoin}</td>
                          <td className="text-success">{data.country}</td>
                          <td className="text-warning">{data.level.name}</td>
                          <td>{data.followers}</td>
                          <td>{data.following}</td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={!data.enableToLive}
                                onChange={() => handleEnableToLive_(data._id)}
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${
                                      !data?.enableToLive ? "-25px" : "35px"
                                    }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {!data?.enableToLive ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={!data.enableVideoCall}
                                onChange={() => handleVideoCall_(data._id)}
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${
                                      !data?.enableVideoCall ? "-25px" : "35px"
                                    }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {!data?.enableVideoCall ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td>
                          <td>
                            {/* <label className="switch">
                              <input
                                type="checkbox"
                                checked={data.blockGame}
                                onChange={() => handleBlockGame_(data._id)}
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${
                                      data?.blockGame ? "-25px" : "35px"
                                    }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data?.blockGame ? "Yes" : "No"}
                                </p>
                              </span>
                            </label> */}
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data.gameBlock}
                                onChange={() => handleBlockGame_(data._id)}
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${
                                      data.gameBlock ? "-25px" : "35px"
                                    }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data.gameBlock ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data.isBlock}
                                onChange={() =>
                                  handleBlockUnblockSwitch_(data._id)
                                }
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${
                                      data.isBlock ? "-25px" : "35px"
                                    }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data.isBlock ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td>
                          {/* <td>
                            {callLevelNames[data.callLevel] || "Loading..."}
                          </td> */}

                          {/* <td>
                          <select
                            value={callLevelNames[data.callLevel] || "N/A"}
                            onChange={(e) =>
                              handleCallLevelChange(data._id, e.target.value)
                            }
                          >
                            {availableCallLevels.map((level) => (
                              <option key={level._id} value={level._id}>
                                {level.name}
                              </option>
                            ))}
                          </select>
                        </td> */}

                     <td >
                      <FormControl fullWidth >
                        <Select 
                          // style={{ color: '#9a9cab' }}
                          labelId={`call-level-label-${data._id}`}
                          value={data.callLevel || "N/A"}
                          onChange={(e) =>
                            handleCallLevelChange(data._id, e.target.value)
                          }
                          renderValue={(selected) => {
                            const selectedLevel = availableCallLevels.find(level => level._id === selected);
                            return selectedLevel ? selectedLevel.name : "Select Level";
                          }}
                          MenuProps={{
                            disableScrollLock: true, 
                          }}
                        >
                          {availableCallLevels.map((level) => (
                            <MenuItem key={level._id} value={level._id } style={{ color: '#9a9cab' }}>
                            <ListItemText primary={level.name}/>
                          </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </td>
                          <td>
                            <Tooltip title="Info">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => handleUserInfo(data)}
                              >
                                <i className="fas fa-info-circle fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="History">
                              <button
                                type="button"
                                className="btn btn-sm btn-success"
                                onClick={() => handleUserHistory(data)}
                              >
                                <i className="fas fa-history fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={totalUser}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getUser,
  handleBlockUnblockSwitch,
  handleEnableToLive,
  handleVideoCall,
  handleBlockGame,
})(UserTable);
