import moment from "moment";
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";

import { getSetting } from "../../store/setting/action";

const UserAudioHistory = (props) => {
  const setting = useSelector((state) => state.setting?.setting);

  const duration = moment.duration(setting?.liveDurationTime);

  let liveDurationTime = moment
    .utc(duration.asMilliseconds())
    .format("HH:mm:ss");

  useEffect(() => {
    props.getSetting();
  }, []);
  return (
    <>
      <table class="table table-striped mt-5">
        <thead>
          <tr>
            <th>No.</th>
            <th>User</th>
            <th>Duration</th>
            <th>Gift</th>
            <th>Coin</th>
            <th>Date</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {props.data?.length > 0 ? (
            props.data.map((data, index) => {
              var audioDate = data?.startTime ? data?.startTime.split(",") : [];
              var durationTime =
                data.endTime && data.startTime
                  ? moment
                      .utc(
                        moment(new Date(data.endTime)).diff(
                          moment(new Date(data.startTime))
                        )
                      )
                      .format("HH:mm:ss")
                  : "00:00:00";

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data?.user}</td>

                  <td
                    className={
                      liveDurationTime > durationTime
                        ? "text-red"
                        : "text-success"
                    }
                  >
                    {durationTime}
                  </td>

                  <td>{data.gifts}</td>
                  <td>{data.rCoin}</td>
                  <td>{audioDate[0]}</td>
                  <td>{audioDate[1]}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8" align="center">
                Nothing to show!!
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default connect(null, { getSetting })(UserAudioHistory);
