import React from 'react'

const UserGameHistory = (props) => {

  return (
    <table class="table table-striped mt-5">
      <thead>
        <tr>
          <th>No.</th>
          <th>Diamond</th>
          <th>Date</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        {props.data?.length > 0 ? (
          props.data.map((data, index) => {
                  var gameDate = data?.date ? data?.date.split(",") : [];
            return (
              <tr key={index}>
                <td>{index + 1}</td>
             
                <td className="text-primary">{data.diamond ? (

                                data.income ? (
                                  data.diamond !== 0 ? (
                                    <td className="text-success">
                                      +{data.diamond}
                                    </td>
                                  ) : (
                                    <td>{data.diamond}</td>
                                  )
                                ) : data.diamond !== 0 ? (
                                  <td className="text-red">-{data.diamond}</td>
                                ) : (
                                  <td>{data.diamond}</td>
                                )
                              ) : (
                                <td>-</td>
                              )}</td>
                <td className="text-info">{gameDate[0]}</td>
                <td className="text-primary">{gameDate[1]}</td>
            
       
             
                <td>{data.purchaseDate}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="8" align="center">
              Nothing to show!!
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default UserGameHistory
